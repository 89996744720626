import { configureAuth, User } from '@stratascratch/authentication'
import { Permissions, PERMISSIONS } from '../types'

const { AuthProvider, tokenUtils } = configureAuth(process.env.ENVIRONMENT)

function getUserPermissions(user: User | null): Permissions {
  if (user?.is_yearly_or_lifetime) {
    return 'Premium Yearly/Lifetime'
  }
  if (user?.is_premium && user?.has_data_projects) {
    return 'Premium (with Data Projects)'
  }
  if (user?.is_premium) {
    return 'Premium'
  }
  if (user) {
    return 'Logged In'
  }
  return 'All'
}

function checkLessonAuthorization(
  lessonPermissions: Permissions,
  user: User | null,
) {
  const userPermissions = getUserPermissions(user)

  const userAuthorizationVal = Object.entries(PERMISSIONS).find(
    ([_key, value]) => value === userPermissions,
  )?.[0]
  const lessonPermissionsVal = Object.entries(PERMISSIONS).find(
    ([_key, value]) => value === lessonPermissions,
  )?.[0]

  if (userAuthorizationVal && lessonPermissionsVal) {
    return userAuthorizationVal >= lessonPermissionsVal
  }
  return false
}

export default checkLessonAuthorization
export { AuthProvider, tokenUtils }
